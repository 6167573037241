import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagenofoundComponent } from './pagenofound/pagenofound.component';
import { LoginComponent } from './login/login.component';
import { FormComponent } from './form/form.component';


const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, data: { titulo: 'Login' } },
  { path: 'formulario/:id', component: FormComponent, data: { titulo: 'Formulario Credito' } },
  { path: '**', component: PagenofoundComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
