import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public submit: Boolean = false;

  constructor(private fb: FormBuilder,
              private router: Router) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.loginForm = this.fb.group({
      id: ['', Validators.required]
    });
  }

  camposInvalidos() {
    if( this.loginForm.invalid && this.submit === true ) {
      return true;
    }
  }

  goToUrl(place: string) {

    let url = '';
    switch (place) {
      case 'PSE':
        url = 'https://checkout.wompi.co/l/VPOS_2Wb1bB';
        break;
  
      case 'PRODUCTOS':
        url = 'https://me-page.org/MLAG7Ii';
        break;
    }

    window.location.href = url;
  }

  login() {

    this.submit = true;
    if( this.loginForm.valid ) {
      this.router.navigateByUrl(`formulario/${ this.loginForm.value.id }`);

    } else {

      Swal.fire({
        title: 'Error!',
        text: 'Necesitamos tu número de documento para continuar',
        icon: 'error',
        confirmButtonText: 'Ok'
      });

    }
  }

}
