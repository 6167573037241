import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { InstacreditoService } from '../services/instacredito.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

  public formCredito: FormGroup;
  public submit: Boolean = false;
  public btnvalid: Boolean = false;
  public documento: string = '';

  constructor(private fb: FormBuilder,
              private instacreditoService: InstacreditoService,
              private route: ActivatedRoute) { }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.documento = params.id
      this.initForm();
    });


  }

  initForm() {

    this.formCredito = this.fb.group({
      nombres: ['', Validators.required],
      apellidos: ['', Validators.required],
      nacionalidad: [''],
      documento: [this.documento, Validators.required],
      fechaDocExpedicion: [''],
      lugarDocExpedicion: [''],
      direccion: ['', Validators.required],
      ciudad: ['', Validators.required],
      fechaNacimiento: [''],
      lugarNacimiento: [''],
      celular: ['', Validators.required],
      telefono: [''],
      email: [''],
      planCredito: ['', Validators.required],
      placa: ['', Validators.required],
      ingresos: ['', Validators.required],
      egresos: [''],
      activos: ['0'],
      pasivos: ['0'],
      patrimonio: ['0'],
      profesion: ['', Validators.required],
      actividad: ['', Validators.required],
      empresaLabora: [''],
      direccionTrabajo: [''],
      ciudadTrabajo: [''],
      referenciaFamiliarNombre: ['', Validators.required],
      referenciaFamiliarTelefono: ['', Validators.required],
      referenciaFamiliarVinculo: ['', Validators.required],
      referenciaPersonalNombre: [''],
      referenciaPersonalTelefono: [''],
      referenciaPersonalVinculo: [''],
      referenciaComercialNombre: [''],
      referenciaComercialTelefono: [''],
      referenciaComercialVinculo: [''],
      comentarios: [''],
      politicas: [ '', Validators.required],
      reporte: ['', Validators.required],
    });

  }

  upsertCredito() {

    this.submit = true;
    if( this.formCredito.valid ) {
      this.btnvalid = true;

      // Si esta reportado no envio los datos
      if (this.formCredito.get('reporte').value === 'SI' ) {

        this.formCredito.reset();
        this.submit = false;
        this.btnvalid = false;

        Swal.fire({
          title: 'Negado',
          html: ' Lo sentimos tu pre-aprobado no es viable',
          icon: 'error',
          confirmButtonText: 'Lo sentimos ...'
        });

      } else {

        this.instacreditoService.crearFormularioCredito(this.formCredito.value)
          .subscribe((resp: any) => {
            this.submit = false;
            this.btnvalid = false;

            if (resp.ok) {

              this.formCredito.reset();

              Swal.fire({
                title: 'Formulario Enviado!',
                html: 'Tu envio fue exitoso! Pronto daremos respuesta del estado de tu credito por medio de whatsapp o llamada telefónica',
                icon: 'success',
                confirmButtonText: 'Muchas gracias!'
              });

            } else {

              Swal.fire({
                title: 'Upps!',
                html: 'Ocurrio un error inesperado, vuelve a intentarlo mas tarde',
                icon: 'error',
                confirmButtonText: 'Entiendo ...'
              });

            }

          }, () => {
            this.submit = false;
            this.btnvalid = false;

            Swal.fire({
              title: 'Upps!',
              html: 'Ocurrio un error inesperado, vuelve a intentarlo mas tarde',
              icon: 'error',
              confirmButtonText: 'Entiendo ...'
            });

          });

      }

    }

  }

  campoInvalido( campo: string) {

    if( this.formCredito.get(campo).invalid && this.submit ) {
      return true;
    } else {
      return false;
    }

  }

}
